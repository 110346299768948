/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import VvVideo from '../assets/videos/vv-v1-2.mp4';
import GridBlock from '../components/gridTwoBlock';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import ImgDisplay from '../components/imgDisplay';
import TopMenu from '../components/topMenu';


const blockBg = img =>
  `url(${img}) center / cover no-repeat fixed padding-box content-box black`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query connectThoSearch {
      whiterLogo: file(relativePath: { eq: "VV.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
    
    const blockCSS = 'flex flex-auto group w-full relative h-1/8 border-0';
  const colorCSS =
    'transition h-full w-full absolute opacity-10 group-hover:opacity-30';

  return (
    <Layout to="../why" label="WHY VIVO VIBE" showMenuIcon className="blackest">
      <SEO title="Vivo Vibe" />
      <TopMenu image={data.whiterLogo}></TopMenu>
      <section className="h-screen w-screen ">
        <div className=" w-screen h-screen fixed">
          <video
            autoPlay
            muted
            playsInline
            loop
            className="object-cover h-full w-full absolute fixed"
          >
            <source src={VvVideo} type="video/mp4" />
          </video>
          <div className="w-full h-full overlay absolute fixed"></div>
        </div>
      
        <div className="relative flex h-screen w-screen flex-col max-thirteenfifty">
      <div class="fourUpPad">      
      <div class="pt-16 sm:pt-32 text-center text-2xl pFix vivo-cont minussixt">
                Why Vivo Vibe?
            </div>
      
      <div class="pt-5 md:pt-16 grid grid-cols-1 md:grid-cols-2 gap-4 text-white text-left">

        <div class="col-span-1 vivo  mr-10 parr bg-black bg-opacity-50">
            <div class="py-4 pl-16  pr-10">
                    <h4 class="grow">TAKE CONTROL OF YOUR FINANCIAL DESTINY</h4>
                <div class="doit">Network marketing is a $220 billion industry with well-known methodologies for growth and success.  Want to make seven figures?  Network Marketing can be the way!</div>                 
            </div>  

        </div>
      
        <div class="col-span-1 vivo mr-10  parr nu-left bg-black bg-opacity-50">
            <div class="py-4 pl-16  pr-10">
                    <h4 class="grow">TAKE CONTROL OF YOUR FINANCIAL DESTINY</h4>
                <div class="doit">Parents can earn working from home, couples can create a family business, and singles can travel the globe.  With Network Marketing, you get to control your own destiny!</div>
            </div>
        </div>

        <div class="col-span-1 vivo mr-10  parr bg-black bg-opacity-50">
            <div class="py-4 pl-16  pr-10">
                    <h4 class="focus">FIND A COMMUNITY AND CONNECT</h4>
                <div class="doit">Network marketing provides an avenue for you to bring together family and friends and interact with others in the community - support is always one friendship away.</div>   
            </div>
        </div>
        
        <div class="col-span-1 vivo  mr-10 parr nu-left bg-black bg-opacity-50">
            <div class="py-4 pl-16  pr-10">
                   <h4 class="improve">IMPROVE YOUR LIFE</h4>
                <div class="doit">Set your own schedule.  Work at your own pace.  Provide a service to others.  Build your business the way YOU want to.  And don’t forget the sales incentives - like travel!</div>
            </div>
        </div>
        </div>
      </div>
         <AniLink className="group w-full h-1/8 border-0 pt-10" paintDrip to="../why-vivo" hex="#4299e1">
        <div className="transition h-full h-1/8 w-full absolute opacity-10 group-hover:opacity-30 bg-white group-hover:bg-black"></div>      
          <div className="w-full absolute items-center justify-center">
            <div className="mt-5 transition font-bold font-header vivo text-white text-center md:mt-8 group-hover:mt-6 md:text-xl mobile-fix">Why Vivo Vibe</div>
          </div>
        </AniLink>
      </div>
      
    
      
      
      </section>

      </Layout>
  );
};

export default IndexPage;
