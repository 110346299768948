/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import HomeIcon from './homeIcon';
// import Header from './header';

const Layout = ({
  to,
  label,
  className,
  children,
  showMenuIcon,
  showFooter,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          name
          social {
            instagram
            facebook
            youtube
            mailto
          }
        }
      }
    }
  `);

  return (
    <section className={`font-body ${className}`}>
      {/* <Header title={data.site.siteMetadata.name} /> */}
      <div className="flex">
        {showMenuIcon && <HomeIcon to={to} label={label} />}
        <main className={`${showMenuIcon ? 'ml-8 vivo' : ''}`}>{children}</main>
      </div>
      {showFooter && <Footer data={data} />}
    </section>
  );
};

Layout.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  showMenuIcon: PropTypes.bool,
  showFooter: PropTypes.bool,
};

Layout.defaultProps = {
  to: '/how-can-we-help-you',
  label: 'menu',
  className: '',
  showMenuIcon: false,
  showFooter: false,
};

export default Layout;
